.navbar {
  background: rgba(0, 0, 0, 0.3);
  position: sticky;
  top: 0;
  justify-content: space-evenly;
  padding-left: 270px;
  padding-right: 270px;
}

.navbar-brand {
  color: #F8F0E3;
  font-family: 'Bebas Neue', cursive;
  transition: transform 0.8s ease-in-out;
}

/* .navbar-brand:hover {
  color: #F8F0E3;
  transform: scale(1.5);
  transition: transform 1s ease-in-out;
} */

#navbar-text {
  color: #F8F0E3;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

#navbar-text {
  position: relative;
}

#navbar-text::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: #F8F0E3;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
  }

#navbar-text:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}
