.name {
  font-size: 140px;
  color: azure;
}

.title {
  font-size: 100px;
  color: azure;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
