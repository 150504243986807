* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #122222 !important;
  color: fff !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* ////////////////// NavBar ////////////////// */

nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.3s ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}

nav.navbar a.navbar-brand {
  color: #fff;
  font-size: 45px;
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 2px;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link:active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 100%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness()
}

.navbar-text button {
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
  font-family: 'Bebas Neue', cursive;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  color: #121212;
  left: 0;
  top: 0;
  transform: scale(0);
}

.navbar-text button:hover {
  color: #121212;
  background-color: #fff;
  border-color: #fff;
  transition: 0.3s ease-in-out;
  transform: scale(1);
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
    box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon::after,
nav.navbar .navbar-toggler-icon::before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon::after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon {
  border-color: transparent;
}

/* ////////////////// Banner ////////////////// */
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url('./components/images/background.jpg');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.banner .intro-text {
  text-align: left
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner h1 {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 16px;
  display: block;
  color: #fff;
}
.banner p {
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
  opacity: 0.75;
  transition: 0.2s ease-in-out;
}

.banner p:hover{
  opacity: 1;
}

.banner button {
  color: #fff;
  background-color: transparent;
  border: none;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  width: 75%;
  height: 100%;
}

.txt-rotate > .wrap {
border-right: 0.08em solid #666;
color: #fff;
}

/* ////////////////// Skills ////////////////// */
.skills {
  position: relative;
  color: #fff;
  background-color: rgb(12, 12, 12);
}

.hidden {
  display: none;
}

.skill-box {
  background-color: rgb(12, 12, 12);
  /* border-radius: 64px; */
  text-align: center;
  padding: 30px 25px;
  margin-top: 80px;
}

.skills h2 {
  font-size: 45px;
  color: #fff;
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 0.8px;
  margin-bottom: 24px;
}

.skills p {
  color: #fff;
  font-size: 24px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}

.skills-top {
  justify-content: space-between;
}

.skills-bot {
  justify-content: space-between;
}

.skill-icon {
  margin: 8px;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* Projects  */

.projects {
  padding: 80px 0;
  position: relative;
  background: #202020;
  /* background-color: rgb(12, 12, 12); */
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.projects h2 {
  letter-spacing: 0.8px;
	font-size: 45px;
  font-family: 'Bebas Neue', sans-serif;
  text-align: center;
  color: #fff;
  margin-bottom: 24px;
}
.projects p {
  color: #B8B8B8;
  font-size: 24px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.projects .nav.nav-pills {
  width: 100%;
  margin: 0 auto;
  /* border-radius: 50px; */
  /* background-color: rgb(255 255 255 / 10%); */
  background-color: rgb(12, 12, 12);
  overflow: hidden;
}
.projects .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.projects .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.projects .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.projects .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.projects .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin: 16px;
  /* border: 2px solid rgb(177, 177, 177);*/
  box-shadow: #9c9c9c;
}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(0deg, #121212 -5.91%, #9c9c9c 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;

}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  /* text-align: left; */
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
  padding: 24px;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
  color: #fff;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
  margin-bottom: 16px;
  text-align: center;
  font-family: 'Bebas Neue', sans-serif;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
  padding: 24px;
}

.projectBtn {
  margin-top: 16px;
  font-weight: 500;
  padding: 4px 8px;
  color: #fff;
  border: 1px solid #fff;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
  font-family: 'Bebas Neue', cursive;
}

.projectBtn:hover {
  color: #121212;
  background-color: #fff;
  border-color: #fff;
  transition: 0.3s ease-in-out;
  transform: scale(1);
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}
/* .App */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 425px) {
  .banner img {
    display: none;
  }

  .banner {
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 100vh;
  }

  .banner h1 {
    font-size: 32px;
  }

  .banner p {
    font-size: 14px;
  }

  nav.navbar {
    background-color: #121212;
    padding: 0;
  }

  .navbar-text {
    justify-content: center;
  }

  .mystack-d {
    display: none;
  }

  .hidden {
    justify-content: center;
  }

  .skills h2 {
    margin-top: 80px;
    margin-bottom: 24px;
  }

  .skill-box {
    display: flex;
    background-color: rgb(12, 12, 12);
    margin-top: 0px;
    padding: 0px 24px;
  }

  .skill-top {
    flex: none;
    align-items: center;
  }

  .skill-bot {
    flex: none;
    align-content: center;
  }

  .projects {
    background: #1e1e1e;
  }

  .contactme {
    display: none;
  }
}
